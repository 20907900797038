
:root{
  --primary: rgb(0, 0, 0);
  --secondary: rgb(255, 255, 255);
  --tertiary: rgb(128, 128, 128);
}

.App {
}

body {
  background-color: black;
  height: 100vh;
}


h1 {
  color: var(--primary);
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  font-size: 50px;
}
h2{
  color: var(--tertiary);
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 20px;
}
h3{
  color: var(--primary);
  font-family: 'Outfit', sans-serif;
  font-weight: 300;
  font-size: 20px;
}
li {
  padding-top: 10px;
}
li:nth-child(1) {
  padding-top: 0;
}

/*LINE*/
.line-area {
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.line {
  height: 1px;
}