
.image-form {
  margin-top: 10vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

#image-container {
  display: flex;
  max-width: 100vw;
  flex-wrap: wrap;
}

#image-preview {
  max-width: 30vh;
  max-height: 15vh;
}

.progress-bar {
  animation-duration: .2s;
  height: 5px;
  background: black;
  margin-top: 20px;
}

button {
  width: 150px;
  border: none;
  background-color: rgb(0, 255, 0);
  color: black;
  cursor: pointer;
}

button:disabled {
  background-color: rgb(255, 0, 0);
  cursor: not-allowed;
}