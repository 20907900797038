*{
    margin: 0;
    padding: 0;
}

/*LOADING SCREEN*/
.loading-screen-left {
    position: absolute;
    z-index: 1100;
    left: 0;
    width: 50vw;
    height: 100%;
    background-color: black;
}
.loading-screen-right {
    position: absolute;
    z-index: 1100;
    right: 0;
    width: 50vw;
    height: 100%;
    background-color: black;
}

/*NAVBAR*/
.nav-area {
    z-index: 1000;
    position: absolute;
    top: 0;
    opacity: 1;
}
.nav-area.active{
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

/*INTRO*/
#intro {
    width: 100%;
    overflow-x: hidden;
}

/*NAME*/
.name {
    position: absolute;
    top: 0;
    left: 0;
    margin: 25px;
    
    color: #FBFBFB;
    font-size: 40px;
    cursor: default;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 500;

    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}
.name.active {
    opacity: 1;
    cursor: pointer;
}

/*Section Titles*/
.section-title {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    font-size: 50px;
}
@media only screen and (max-width: 500px) {
    .section-title {
        font-size: 10vw;
    }
}