.gallary {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100vw;
    min-height: 100vh;
    background-color: black;
}
#headerBACKUP {
    height: 46vh;
    width: 100vw;
    position: relative;

    background-image: url("../../../public/assests/gallary-moon.jpg");
    background-size: 100vw;
    background-position-x: center ;
    background-position-y: 50% ;
    background-repeat: repeat-x;
}
#header {
    height: 46vh;
    width: 100vw;
    position: relative;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

#bar-box {
    width: 80vw;
    
    display: flex;
}
.bar {
    background-color: gray;
    height: 40vh;
    width: 5vw;
    margin: 0 1vw 0 1vw ;
}



#header-title {
    position: absolute;
    width: 100vw;
    bottom: 5vh;
    display: flex;
    justify-content: center;
    padding-bottom: -50px;
}
#gallary-title {

    text-align: center;
    font-weight: 900;
    font-size: 200px;
    letter-spacing: -2px;
    font-family: 'Josefin Sans', sans-serif;
    color: white;
}

@media only screen and (max-width: 1920px) {
    #gallary-title{
        font-size: calc(4vw + 80px);
    }
}


.grid {
    display: flex;
    flex-wrap: wrap;
    width: calc(100vw - 10px);
    margin: 5px;
}

.grid::after {
    content: '';
    flex-grow: 999999999;
}

.grid>figure {
    margin: 5px;
    background-color: violet;
    position: relative;
}

.grid>figure>i {
    display: block;
}

.grid>figure>img {
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: bottom;
}

.white-line-area-gallary {
    z-index: 200;
    width: 100vw;
    background-color: black;
    padding: 0 0 0 0;
    display: flex;
    justify-content: center;
}
.white-line-gallary {
    width: calc(100vw - 20px);
    height: 1px;
    background-color: gray;
}

#tag-area {
    height: 10vh;
    background-color: black;
}