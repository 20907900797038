#landing-area {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;

}

#space-text-area {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;

}
#space-text-container{
    position: absolute;

    height: 50vh;
    width: 60vw;
    max-width: 1536px;
    
    letter-spacing: -2px;
    font-family: 'Josefin Sans', sans-serif;

}

#name-text{
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
    font-weight: 900;
    font-size: 200px;
    opacity: 0;
}
#name-text span {
    background: -webkit-linear-gradient(white, #38495a);
    background-clip:text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#titles-text {
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: right;
    font-weight: 600;
    font-size: 70px;
    opacity: 0;
}
#titles-text span {
    background: -webkit-linear-gradient(white, #38495a);
    background-clip:text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 1920px) {
    #space-text-container {
        height: calc((55vw + 55vh) / 2);

    }
    #name-text{
        font-size: calc((10vw + 10vh) / 2);
    }
    #titles-text {
        font-size: calc((4vw + 4vh) / 2);
    }
}


#stars-background {
    position: relative;
    height: 140vh;
    width: 100vw;
    background: radial-gradient(at 50% 120%, #272730 0%, #272730 40%, #171720 80%, #070710 100%);
}

#moon-axel{
    position: absolute;
    width: 1px;
    background-color: aqua;
    overflow: visible;
    height: 100vh;
    display: flex;
    justify-content: center;
}
#moon-gltf {
    position: absolute;

}

