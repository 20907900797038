/*TRANSITION*/
#atmosphere-transition {
    position: absolute;
    top: 90vh;
    height: 120vh;
    width: 100vw;
    background: linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(156,238,255,1) 20%, rgba(2,206,252,1) 30%, rgba(40,80,199,1) 49%, rgba(39,39,48,.8) 62%, rgba(39,39,48,0) 100%);
    display: flex;

    flex-direction: column;
    align-items: center;
}

#aboutme-area {
    width: 50vw;
}

/*SCENE*/
#cloud-scene-area{
    position: relative;
    display: flex;
    justify-content: center;
}
#cloud-scene{
    position: relative;
    display: flex;
    width: 100vw;
    justify-content: center;
    height: 50vh;
}
#scene-img {
    height: 55vh;
    width: 100vw;
    position: absolute;

    background-image: url("../../../../public/assests/hills.png");

    background-position-x: center ;
    background-position-y: 15% ;
    background-repeat: repeat-x;
}
#cliff-img{ 
    height: 50vh;
    width: 100vw;
    position: absolute;

    background-image: url("../../../../public/assests/cliff2.png");
    background-size: 800px;
    background-position-x: center ;
    background-position-y: 15% ;
    background-repeat: no-repeat;
}
#clouds-img {
    height: calc(50vh + 5px);
    width: 100vw;
    position: absolute;

    background-image: url("../../../../public/assests/clouds.png");
    background-size: 120vw 35vh;
    background-position-x: center ;
    background-position-y: 100% ;
    background-repeat: repeat-x;
    
}
#projects-title-area {
    color: black;
    position: absolute;
    bottom: 0;
}

/*about me text*/
#aboutme-area {
    position: relative;
    width: 100vw;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

}
#intro-container {
    width: 80vw;
    max-width: 800px;

    margin-bottom: 25px;

}
#mission-container{
    width: 80vw;
    max-width: 800px;

    margin-bottom: 25px;
}
#current-container{
    width: 80vw;
    max-width: 800px;

}