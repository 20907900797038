#footer-area {
    width: 100vw;

    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3vh 0 3vh 0;
}
.feet {

    text-align: center;

    color: white;
    width: 80vw;
    max-width: 680px;
}

#icon-container {
    display: flex;
    margin: 25px 0 25px 0;
}
#linkedin-icon {
    height: 50px;
    width: 50px;

    background: url("../../../../public/assests/icons/linkedin_icon.png");
    background-size: 50px 50px;
    background-repeat: no-repeat;
}
#email-icon {
    height: 50px;
    width: 50px;
    margin: 0 25px 0 25px;
    background: url("../../../../public/assests/icons/email_icon.png");
    background-size: 50px 50px;
    background-repeat: no-repeat;
}
#insta-icon {
    height: 50px;
    width: 50px;

    background: url("../../../../public/assests/icons/insta_icon.png");
    background-size: 50px 50px;
    background-repeat: no-repeat;
}