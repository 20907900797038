.projects {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.projectsHeaderImg{
    width: 100%;
    height: 700px;
    object-fit: cover;
}

.scrollme {
    height: 200vh;
}