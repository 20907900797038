#down-in-the-dice-main {

}
#DITD-header {
    width: 100vw;
    height: 50vh;
    background-color: black;
    display: flex;
    justify-content: center;
}
#DITD-title-container{
    position: absolute;
    bottom: 0;
}
#DITD-title {
    color: white;
    text-align: center;
    font-weight: 900;
    font-size: 100px;
    letter-spacing: -2px;
    font-family: 'Josefin Sans', sans-serif;
}