
/*INDICATORS*/
#indicator-area {
    position: absolute;
    height: 100vh;
    width: 4vw;
    z-index: 999;
    display: flex;
    transform: translateX(calc(2vw));
    justify-content: center;

    flex-direction: column;
}

.intro-indicator{
    width: 3vw;

    background-color: gray;
    border-radius: 10px;
    margin: 20px 0 20px 0;
    height: 6px;
    cursor: pointer;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.intro-indicator.active{
    transform: scale(1.5) translateX(.5vw);
    background-color: gold;
}
.projects-indicator{
    width: 3vw;

    background-color: gray;
    border-radius: 10px;
    margin: 20px 0 20px 0;
    height: 6px;
    cursor: pointer;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.projects-indicator.active{
    transform: scale(1.5) translateX(.5vw);
    background-color: gold;
}
.jobs-indicator{
    width: 3vw;

    background-color: gray;
    border-radius: 10px;
    margin: 20px 0 20px 0;
    height: 6px;
    cursor: pointer;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.jobs-indicator.active{
    transform: scale(1.5) translateX(.5vw);
    background-color: gold;
}
.skills-indicator{
    width: 3vw;

    background-color: gray;
    border-radius: 10px;
    margin: 20px 0 20px 0;
    height: 6px;
    cursor: pointer;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.skills-indicator.active{
    transform: scale(1.5) translateX(.5vw);
    background-color: gold;
}