/*SMOKE*/

.smoke {
    position:absolute;
    top:50%;
    lefT:50%;
    transform:translate(-50%,-50%);
}

.smoke ul {
    margin:0;
    padding:0;
    display:flex;  
}

.smoke ul li {
    list-style:none;

    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    font-size: 50px;

    animation: smoke 10s linear infinite;
    filter:blur(1px);
    color: black;
    display: inline-block;
}
@media only screen and (max-width: 500px) {
    .smoke ul li {
        font-size: 10vw;
    }
}
@keyframes smoke {
    0% {
        transform: rotate(0deg) translateY(0px);
        opacity: 1;
        filter:blur(1px);
        color: black;
    }
    20% {
        transform: rotate(65deg) translateY(-200px);
        opacity: 0;
        filter:blur(20px);
        color: white;
    }
    40% {
        transform: rotate(0deg) translateY(0px);
        opacity: 0;
        filter:blur(1px);
    }
    60% {
        transform: rotate(0deg) translateY(0px);
        opacity: 1;
        filter:blur(1px);
        color: black;
    }
}

.smoke ul li:nth-child(1){
    animation-delay:0s
}
.smoke ul li:nth-child(2){
    animation-delay:.2s
}
.smoke ul li:nth-child(3){
    animation-delay:.4s
}
.smoke ul li:nth-child(4){
    animation-delay:.6s
}
.smoke ul li:nth-child(5){
    animation-delay:.8s
}
.smoke ul li:nth-child(6){
    animation-delay:1.2s
}
.smoke ul li:nth-child(7){
    animation-delay:1.4s
}
.smoke ul li:nth-child(8){
    animation-delay:1.6s
}