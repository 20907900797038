
/*TITLE*/
.glitch {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    font-size: 50px;

    color: white;
    position: relative;

    animation: glitch 5s 5s infinite;
}
@media only screen and (max-width: 500px) {
    .glitch {
        font-size: 10vw;
    }
}
  
.glitch::before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: -5px 0 magenta;
    background: black;
    overflow: hidden;
    top: 0;
    animation: noise-1 3s linear infinite alternate-reverse, glitch 5s 5.05s infinite;
}
  
.glitch::after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -5px 0 lightgreen;
    background: black;
    overflow: hidden;
    top: 0;
    animation: noise-2 3s linear infinite alternate-reverse, glitch 5s 5s infinite;
}
  
@keyframes glitch {
    1%{
      transform: rotateX(10deg) skewX(90deg);
    }
    2%{
      transform: rotateX(0deg) skewX(0deg);
    }
}
  
@keyframes noise-1 {
    $steps: 3;
    @for $i from 1 through $steps {
      #{percentage($i*( calc(1/$steps)) )} {
        $top: random(100);
        $bottom: random(101 - $top);
        clip-path: inset(#{$top}px 0 #{$bottom}px 0);
      }
    }
}
  
@keyframes noise-2 {
    $steps: 3;
    @for $i from 0 through $steps {
      #{percentage($i*(calc(1/$steps)))} {
        $top: random(100);
        $bottom: random(101 - $top);
        clip-path: inset(#{$top}px 0 #{$bottom}px 0);
      }
    }
}
  
.scanlines {
    overflow: hidden;
    mix-blend-mode: difference;
}
  
.scanlines::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  
    background: repeating-linear-gradient(
      to bottom,
      transparent 0%,
      rgba(255, 255, 255, 0.05) .5%,
      transparent 1%
    );
  
    animation: fudge 7s ease-in-out alternate infinite;
}
  
  
@keyframes fudge {
    from {
      transform: translate(0px, 0px);
    }
    to {
      transform: translate(0px, 2%);
    }
}
  
.glow {
    @extend .glitch;
    text-shadow: 0 0 1000px rgb(223, 191, 191);
    color: transparent;
    position: absolute;
    top: 0;
}