.cave-scene {
    width: 100vw;
    height: 50vh;
    top: -1vh;

    background-image: url("../../../../public/assests/cave.png");
    background-size: calc(50vh*4) 50vh;

    background-repeat: repeat-x;
}
.skill-title-area{
    position: relative;
    background-color: white;
    width: 100vw;
    justify-content: center;
    display: flex;
    padding-Top: 30px;
}

.skills-area {
    width: 100vw;
    padding-bottom: 20px;
    position: relative;
    display: flex;

    justify-content: center;
    background-color: white;
}
.skills-flexbox {
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.skills-container {
    background-color: white;
    padding: 20px 20px 20px 20px;
    margin: 10px;
    outline: 1px solid var(--tertiary);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    position: relative;


}
@media only screen and (max-width: 720px) {
    .skills-area {
        padding-bottom: 10px;
    }
    .skill-title-area{
        padding-Top: calc(10px + 15px);
    }
    .skills-container {
        padding: 10px 20px 20px 20px;
        margin: 10px;
        gap: 10px;
    }
}
