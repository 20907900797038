#backdrop-fixer{
    position: absolute;
    top: 0;
    z-index: 1000;
    width: 100vw;
    display: flex;
    justify-content: center;
}
.backdrop{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: -100vh;

    background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0,0,0,0) 100%);
    transition: all 0.5s ease-in-out;
}

.backdrop.active{
    top: 0;
    transition: all 0.5s ease-in-out;
}

.backdrop img {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    max-width: 80vw;
    max-height: 80vh;
}
.backdrop.active img {
    transition: all 0.5s ease-in-out;
    opacity: 1;
}

.backdrop video {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    max-width: 80vw;
    max-height: 80vh;
}
.backdrop.active video {
    transition: all 0.5s ease-in-out;
    opacity: 1;
}