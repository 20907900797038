
/*PROJECTS*/
.jobs-title-area {
    position: relative;
    background-color: black;
    width: 100vw;
    height: 9vh;
    justify-content: center;
    align-items: flex-end;
    display: flex;
}
#title-container {
    display: flex;
    align-items: flex-end;
}

/*PROJECTS TEMPLATE*/
.project-area{
    position: relative;
    
    background-color: black;
    width: 100vw;
    position: relative;
    display: flex;
    justify-content: center;
}
.project-container {
    max-width: 1536px;
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
}
.text-container {
    max-width: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    margin-right: 2%;
    
}

.project-title {
    color: white;
    width: 100vw;
}
.skills-titles{
    margin: 5px 0 15px 0;
    color: white;
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
}
.tag-box {
    border-radius: 7px;
    margin-right: 10px;
    padding: 5px;
    margin: 4px;
}
.project-text{
    color: white;
    width: 100vw;
}
a {
    color: white;
 
}
a:hover {
    color: gold;
 
}

.image-flexbox {
    background-color: black;
    display: flex;
    flex-flow: row wrap;

    overflow: hidden;
    max-width: 58%;

    box-shadow: -5px -5px 9px #ffffff73, 5px 5px 7px rgba(94, 104, 121, 1);
    border-radius: 15px;
    z-index: 1;
}


.line {
    max-width: 1536px;
    width: 80vw;
}
#mini-note {
    font-size: 15px;
    margin-left: 25%;
}

.swiper {
    max-width: 50%;
    aspect-ratio: 1.9;
    
}
  
.swiper-slide {
    border-radius: 18px;
}

.swiper-slide>img {
    width:100%; 
    height:100%;

    margin-bottom: -5px;
    object-fit: cover;
}
.swiper-slide>video {
    
    width:100%; 
    height:100%;
    
    margin-bottom: -5px;
    object-fit: cover;
}  


.swiper-slide>img:hover{
    filter: brightness(110%) ;
}
.swiper-slide>video:hover{
    filter: brightness(110%) ;
}

.white-screen {
    width: 50%;
    aspect-ratio: 1.9;
    border-radius: 18px;
    background-color: white;
    margin: 0px 4% 0px 4%; 
}

@media only screen and (max-width: 1500px) {
    .text-container {
        max-width: 80vw;
        margin-right: 0px;
        margin-bottom: 30px;
    }
    .image-flexbox {
        width: 80vw;
        max-width: none;
    }
    .swiper {
        width: 70vw;
        max-width: none;
    }
    .white-screen {
        width: 70vw;
    }
    #mini-note {
        margin-left: 0;
    }
    
}
@media only screen and (max-width: 720px) {
    .project-title {
        font-size: calc((4vw + 4vh) / 2);
    }
    .skills-titles{
        font-size: calc((2.5vw + 2.5vh) / 2);
        margin: 0;
    }
    .project-text{
        font-size: calc((3vw + 3vh) / 2);
    }
    #mini-note {
        font-size: calc((2vw + 2vh) / 2);
    }
}

#IntoOrbit-title {
    height: 160px;
    width: 300px;

    background: url("../../../../public/projects/IntoOrbit/IntoOrbit.png");
    background-size: 300px 160px;
    background-repeat: no-repeat;
}

#fill {
    height: 100vh;
    width: 100vw;

    position: absolute;
    top: 0;
}

.video-container{
    width: 100vw;
    height: 100vh;
}
    
iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 140%;
    min-height: 100%;

    transform: translate(-50%, -50%);
}

.resize-youtube-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 110%;

    transform: translate(-50%, -50%);
}