* {
    padding: 0;
    margin: 0;
}
.nav{
    position: absolute;
    top: 0;
    width: 100vw;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 500;
    display: flex;
    justify-content: center;
}
#padding {
    position: absolute;
    top: -25vh;
    height: 25vh;
    width: 100vw;
    background-color: black;
}
.gradient {
    position: absolute;
    top: 0;
    background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0,0,0,0) 100%);
    width: 100vw;
    height: 80px;
    transition: height 0.5s ease-in;
}

.navList{
    position: absolute;
    top: -160px;
    width: 70vw;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    transition: all 0.5s ease-in;
}
.navList.active{
    top: 0px;
    transition: all 0.5s ease-in;
}
.btn {
    text-decoration: none;
    padding: 20px 0 12px 0;
    width: 50vw;
    min-width: 180px;
    max-width: 300px;
    
    font-size: 25px;
    position: relative;
    margin: 10px;
    color: white;

    display: flex;
    justify-content: center;
}
.btn:hover {
    transition: all 0.2s ease-in-out;
    font-weight: 900;
    color: gold;
}
@media only screen and (max-width: 1280px) {
    .gradient.active {
        height: 100vh;
    }
    .navList{
        flex-direction: column;
        height: 500px;
        top: -500px;
    }
    .navList.active{
        flex-direction: column;
        height: 100vh;
        top: 0;
    }
}
.btn::after, .btn::before {
    border: 3px solid;
    border-color: #FBFBFB !important;
    content: "";
    position: absolute;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    left: 0;
    bottom: 0;

    transition: transform 0.3s ease;
}
.btn:hover::after {
    transform: translate(-5px, -5px);
}
.btn:hover::before {
    transform: translate(5px, 5px);
}

.btn2 {
    text-decoration: none;
    padding: 20px 0 12px 0;
    width: 50vw;
    min-width: 180px;
    max-width: 300px;
    
    font-size: 25px;
    position: relative;
    margin: 10px;
    color: #FBFBFB;

    display: flex;
    justify-content: center;

    color: gray;
    border: 3px solid gray;
}
.cover {
    position: absolute;
    top: 40px;
    font-weight: 300;
    font-size: 15px;
}


/*menu button*/
.menu-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px 0 0 0;

    width: 90px;
    height: 50px;
    
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}
.menu-btn-burger {
    content: '';
    position: absolute;
    width: 50px;
    height: 3px;
    background-color: white;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
}
.menu-btn-burger::before,
.menu-btn-burger::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 3px;
    background-color: white;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
}
.menu-btn-burger::before {
    transform: translateY(-16px);
}
.menu-btn-burger::after {
    transform: translateY(16px);
}
.menu-btn-burger.active {
    transform: translateX(50px);

    box-shadow: none;
}
.menu-btn-burger.active::before {
    transform: rotate(-45deg) translate(-35px, -35px)
}
.menu-btn-burger.active::after {
    transform: rotate(45deg) translate(-35px, 35px)
}