.icon-skill-group{
    display: flex;
    align-items: center;
    justify-content: center;

}

.skill-icon {
    width: calc((7vh + 7vw) / 2);
    height: calc((7vh + 7vw) / 2);
    max-width: 100px;
    max-height: 100px;
    margin-right: 10px;
}

.skill-container {
    max-width: 300px;
    min-width: 260px;
    height: calc((7vh + 7vw) / 2);
    max-height: 80px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
.skill-name {
    width: 90%;
    height: 60%;
    font-family: 'Outfit', sans-serif;
    font-weight: 400;
    font-size: 30px;
    display: flex;
    align-items: flex-end;
}
.skill-percentage {
    width: 10%;
    height: 60%;
    font-family: 'Outfit', sans-serif;
    font-weight: 300;
    font-size: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
@media only screen and (max-width: 720px) {
    .skill-name {
        font-size: calc((3vw + 3vh) / 2);
    }
    .skill-percentage {
        font-size: calc((2vw + 2vh) / 2);
    }
    .skill-container {
        width: 54vw;
    }
}

.skill-bar-container{
    position: relative;
    width: 100%;
    height: 15%;
    border-radius: 25px;
    box-shadow: -5px -5px 9px #ffffff73, 5px 5px 7px rgba(94, 104, 121, 1);
}
.skill-bar{
    width: 100%;
    height: 100%;
    border-radius: 25px;
    -webkit-mask:linear-gradient(#fff 0 0);
    mask:linear-gradient(#fff 0 0);
    transition: all 0.5s ease-in-out;
}
.skill-bar::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-image: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(139,0,189,1) 25%, rgba(255,162,10,1) 75%, rgba(255,248,101,1) 100%); 
}