:root {
    --pixel-size: 0.15vh;
    --character-width: 48;
    --character-height: 64;
    --character-animation-tracker: 0;
    --city-width: 1920;
    --city-height: 360;
    --clouds-width: 640;
    --walking-width: 36;
    --walking-height: 96;
}


#pixel-scene{
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    justify-content: center;

    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: pixelated;
    image-rendering: crisp-edges;
}

/*TRANSITION*/
#cloud-transition-2 {
    z-index: 1;
    position: absolute;
    top: 0;
}
#clouds1 {
    top: calc(-1vh - 5px);
    height: 10vh;
    width: calc(640 * var(--pixel-size) + 100vw);
    position: absolute;

    background-image: url("../../../../public/assests/clouds1.png");
    background-size: calc( 1 * var(--clouds-width) * var(--pixel-size));

    background-repeat: repeat-x;
}
#clouds2 {
    top: -1vh;
    height: 15vh;
    width: calc(640 * var(--pixel-size) + 100vw);
    position: absolute;

    background-image: url("../../../../public/assests/clouds2.png");
    background-size: calc( 1 * var(--clouds-width) * var(--pixel-size));

    background-position-y: 0;
    background-repeat: repeat-x;
}
#clouds3 {
    top: -1vh;
    height: 20vh;
    width: calc(640 * var(--pixel-size) + 100vw);
    position: absolute;

    background-image: url("../../../../public/assests/clouds3.png");
    background-size: calc( 1 * var(--clouds-width) * var(--pixel-size));

    background-position-y: 0;
    background-repeat: repeat-x;
}
#clouds4 {
    top: -1vh;
    height: 20vh;
    width: calc(640 * var(--pixel-size) + 100vw);
    position: absolute;

    background-image: url("../../../../public/assests/clouds4.png");
    background-size: calc( 1 * var(--clouds-width) * var(--pixel-size));

    background-position-y: 0;
    background-repeat: repeat-x;
}


/*SCENE*/

#city-scene {
    width: 100vw;
    margin-top: 10vh;
    height: calc( 1 * var(--city-height) * var(--pixel-size));
    background-image: url("../../../../public/assests/WEBSITE3.png");
    background-size: calc( 1 * var(--city-width) * var(--pixel-size));
    background-position-x: center ;
}

#pixel-mouse-area {
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: calc( 1px + var(--city-height) * var(--pixel-size));
}

#train1 {
    position: absolute;
    bottom: 0;
    height: calc( 1 * var(--city-height) * var(--pixel-size));
    width: calc(680 * var(--pixel-size));

    background-image: url("../../../../public/assests/train1.png");
    background-size: calc( 680 * var(--pixel-size));

    background-position-y: 0;
    background-repeat: none;
}

#character {
    width: calc(1 * var(--character-width) * var(--pixel-size));
    height: calc(1 * var(--character-height) * var(--pixel-size));
    overflow: hidden;
    position: absolute;
    bottom: 0;
}
.character-spritesheet {
    animation: moveSpritesheet 1s steps(8) infinite;
    width: calc(384 * var(--pixel-size));
    position: absolute;
}

.walking1 {
    width: calc(1 * var(--walking-width) * var(--pixel-size));
    height: calc(1 * var(--walking-height) * var(--pixel-size));
    overflow: hidden;
    position: absolute;
    bottom: 0;
}

.walking {
    animation: moveSpritesheet 1s steps(12) infinite;
    width: calc(432 * var(--pixel-size));
    position: absolute;
}


.left {
    --character-animation-tracker: 0;
}
.right {
    --character-animation-tracker: 1;
}
.idle {
    top: calc(-1 * var(--character-height) * (0 + var(--character-animation-tracker)) * var(--pixel-size));
}
.running {
    top: calc(-1 * var(--character-height) * (2 + var(--character-animation-tracker)) * var(--pixel-size));
}

@keyframes moveSpritesheet {
    from {
       transform: translate3d(0px,0,0)
    }
    to {
       transform: translate3d(-100%,0,0)
    }
}

#coord-display{
    background:black;
    color: white;
    font-size: 32px;
    z-index: 1;
    position: absolute;
    bottom: 0;
}
